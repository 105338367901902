
:root {
    --PRIMARY_COLOR_ONE: #E8b504;
    --PRIMARY_COLOR_TWO: #E8b504;
    --white: #faf0e6;
    --SECONDARY_1_COLOR: #ebe6e3;
    --SECONDARY_2_COLOR: #89a09b;
    --SECONDARY_3_COLOR: #c9bab1;
    --SECONDARY_4_COLOR: #246f6c;
    --SECONDARY_5_COLOR: #ededed;
}

.ant-spin .ant-spin-dot-item {
    background-color: var(--PRIMARY_COLOR_ONE);
}

.logo{
    width: 100%;
    max-width: 200px;
    min-width: 200px;
}

.ql-editor{
    min-height:150px;
}

.ant-btn{
    border-radius: 5px !important;
}
.drag-n-drop {
    border-style: dashed;
    border-width: 2px;
    border-color: var(--ant-primary-2);
    padding: 10px;
}

.custom-button-bootstrap {
    background: var(--PRIMARY_COLOR_ONE);
    border-color: var(--PRIMARY_COLOR_ONE);
}


.custom-button-bootstrap:hover {
    background: var(--PRIMARY_COLOR_TWO);
    border-color: var(--PRIMARY_COLOR_TWO);
}

.main-color {
    color: var(--PRIMARY_COLOR_ONE) !important;
}
.border-main-color {
    border-color: var(--PRIMARY_COLOR_ONE) !important;
}

.custom-grey-color {
    color: #3c3c3b;
}

.primary-bg {
    background: var(--PRIMARY_COLOR_ONE) !important;
}


.paper{
    background: white;
    padding: 1rem;
    border-radius: 7px;
    height: fit-content;
}

.toolbar_header {
    height: 56px;
    padding: 0.75rem;
    display: flex;
}

.page {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.pre-line{
    white-space: pre-line;
}

.font-size-11 {
    font-size: 11px !important;
}
.font-size-12 {
    font-size: 12px !important;
}
.font-size-13 {
    font-size: 13px !important;
}
.font-size-14 {
    font-size: 14px !important;
}
.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-32 {
    font-size: 32px !important;
}

.font-size-36 {
    font-size: 36px !important;
}

.font-size-42 {
    font-size: 42px !important;
}

.font {
    font-family: "Poppins";
}

html {
    box-sizing: border-box;
    font-family: "Poppins", "Roboto", "Times New Roman", Times, serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
}

h2 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.25;
    color: #2a2d32;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
h1 {
    font-size: 24px;
    font-weight: bold;
    color: #2a2d32;
    line-height: 30px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
.h3,
h3 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
{
    font-family: "Poppins";
    font-size: 14px;
}

.border-radius-10{
    border-radius: 10px;
}

.border-radius-5{
    border-radius: 5px;
}


.border-radius-20{
    border-radius: 20px;
}


.border-radius-5-end{
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.z-index-m1 {
    z-index: -1 !important;
}

.z-index-0 {
    z-index: 0 !important;
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-2 {
    z-index: 2 !important;
}

.z-index-100 {
    z-index: 100 !important;
}


.custom-modal-lg {
    max-width: 85% ;
    min-width: 85% ;
    width: 85% ;
    margin: 6vh auto 12vh !important;
    border-radius: 10px !important;
    padding-bottom: 5rem !important;
}
.custom-modal-md {
    max-width: 65% ;
    min-width: 65% ;
    width: 65% ;
    margin: 6vh auto 12vh !important;
    border-radius: 10px !important;
    padding-bottom: 5rem !important;
}
.custom-modal-sm {
    max-width: 50% ;
    min-width: 50% ;
    width: 50% ;
    margin: 6vh auto 12vh !important;
    border-radius: 10px !important;
    padding-bottom: 5rem !important;
}

.bg-none{
    background: none!important;
}

.vertical-align-0{
    vertical-align: 0 !important;
    line-height: 0 !important;
}

.spinner {
    -webkit-animation: spin 1.75s linear infinite; /* Safari */
    animation: spin 1.75s linear infinite;
}

/*.ant-table-cell{*/
/*  background: none !important;*/
/*}*/

.anticon>*{
    vertical-align: 0 !important;
    line-height: 0 !important;
}



/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 900px) {
    .custom-modal-lg, .custom-modal-md, .custom-modal-sm {
        margin-top: 3vh !important;
        max-width: 95% !important;
        min-width: 95% !important;
        width: 95% !important;
    }

    .font-size-11 {
        font-size: 9px !important;
    }

    .font-size-12 {
        font-size: 10px !important;
    }

    .font-size-13 {
        font-size: 11px !important;
    }

    .font-size-14 {
        font-size: 12px !important;
    }

    .font-size-15 {
        font-size: 13px !important;
    }

    .font-size-16 {
        font-size: 14px !important;
    }

    .font-size-17 {
        font-size: 15px !important;
    }

    .font-size-18 {
        font-size: 16px !important;
    }

    .font-size-20 {
        font-size: 18px !important;
    }

    .font-size-22 {
        font-size: 20px !important;
    }


    .font-size-32 {
        font-size: 26px !important;
    }

    .font-size-36 {
        font-size: 30px !important;
    }

    .font-size-42 {
        font-size: 36px !important;
    }
}

.ant-form-item-label
> label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    font-size: 16px;
}
